"use strict";
// import { standards } from '@psychedelic/dab-js';
Object.defineProperty(exports, "__esModule", { value: true });
exports.DEFAULT_ASSETS = exports.DEFAULT_TOKENS = exports.TOKENS = void 0;
const constants_1 = require("./constants");
exports.TOKENS = {
    ICP: {
        symbol: 'ICP',
        canisterId: constants_1.LEDGER_CANISTER_ID,
        name: 'ICP',
        decimals: 8,
        // standard: standards.TOKEN.icp,
    },
    XTC: {
        symbol: 'XTC',
        canisterId: 'aanaa-xaaaa-aaaah-aaeiq-cai',
        name: 'Cycles',
        decimals: 12,
        // standard: standards.TOKEN.xtc,
    },
    WTC: {
        symbol: 'WTC',
        canisterId: '5ymop-yyaaa-aaaah-qaa4q-cai',
        name: 'Wrapped Cycles',
        decimals: 12,
        // standard: standards.TOKEN.dip20,
    },
    WICP: {
        symbol: 'WICP',
        canisterId: 'utozz-siaaa-aaaam-qaaxq-cai',
        name: 'Wrapped ICP',
        decimals: 8,
        // standard: standards.TOKEN.wicp,
    },
    BTKN: {
        symbol: 'BTKN',
        canisterId: 'cfoim-fqaaa-aaaai-qbcmq-cai',
        name: 'Beta Token',
        decimals: 8,
        // standard: standards.TOKEN.dip20,
    },
    DUST: {
        symbol: 'DUST',
        canisterId: '4mvfv-piaaa-aaaak-aacia-cai',
        name: 'Dust Token',
        decimals: 8,
        // standard: standards.TOKEN.dip20,
    },
};
exports.DEFAULT_TOKENS = [exports.TOKENS.ICP, exports.TOKENS.XTC, exports.TOKENS.WICP];
exports.DEFAULT_ASSETS = exports.DEFAULT_TOKENS.reduce((acum, token) => ({ ...acum, [token.canisterId]: { token, amount: '0' } }), {});
