"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.from32bits = exports.to32bits = void 0;
const to32bits = (num) => {
    let b = new ArrayBuffer(4);
    new DataView(b).setUint32(0, num);
    return Array.from(new Uint8Array(b));
};
exports.to32bits = to32bits;
const from32bits = (ba) => {
    let value = 0;
    for (let i = 0; i < 4; i++) {
        value = (value << 8) | ba[i];
    }
    return value;
};
exports.from32bits = from32bits;
