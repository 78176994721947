"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.principalToAccountIdentifier = void 0;
const principal_1 = require("@dfinity/principal");
const sha224_1 = require("./sha224");
const crc_1 = require("./crc");
const hex_1 = require("./hex");
const bit_1 = require("./bit");
const principalToAccountIdentifier = (p, s = 0) => {
    const padding = new Buffer('\x0Aaccount-id');
    const array = new Uint8Array([
        ...padding,
        ...principal_1.Principal.fromText(p).toUint8Array(),
        ...getSubAccountArray(s),
    ]);
    const hash = (0, sha224_1.sha224)(array);
    const checksum = (0, bit_1.to32bits)((0, crc_1.getCrc32)(hash));
    const array2 = new Uint8Array([...checksum, ...hash]);
    return (0, hex_1.toHexString)(array2);
};
exports.principalToAccountIdentifier = principalToAccountIdentifier;
const getSubAccountArray = (s) => {
    if (Array.isArray(s)) {
        return s.concat(Array(32 - s.length).fill(0));
    }
    else {
        //32 bit number only
        return Array(28)
            .fill(0)
            .concat((0, bit_1.to32bits)(s ? s : 0));
    }
};
