"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const styled_components_1 = require("styled-components");
const GlobalStyle = (0, styled_components_1.createGlobalStyle) `
  body {
    margin: 0;
    padding: 0;
    font-family: 'Montserrat', 'Courier New', Courier, monospace, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: ${(props) => props.theme.antd.colorPrimary};
  }

  .hide-scrollbar {
    // to hide scrollbar
    &::-webkit-scrollbar {
      display: none; /* Chrome, Safari and Opera */
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
`;
exports.default = GlobalStyle;
