"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isPrincipalId = exports.exponentialMovingAverage = exports.createIntegerArray = exports.tokenIndexToNftId = exports.nftIdToIndex = exports.convertUint8ArrayToInteger = exports.getDaysArray = exports.asyncCallWithTimeout = exports.formatToLocaleString = exports.roundToNDigits = void 0;
const principal_1 = require("@dfinity/principal");
function roundToNDigits(num, digits = 2) {
    return Math.round(num * 10 ** digits) / 10 ** digits;
}
exports.roundToNDigits = roundToNDigits;
const formatToLocaleString = (num, digits = 2) => {
    return num.toLocaleString(undefined, {
        minimumFractionDigits: digits,
        maximumFractionDigits: digits,
    });
};
exports.formatToLocaleString = formatToLocaleString;
/**
 * Call an async function with a maximum time limit (in milliseconds) for the timeout
 * @param {Promise<any>} asyncPromise An asynchronous promise to resolve
 * @param {number} timeLimit Time limit to attempt function in milliseconds
 * @returns {Promise<any> | undefined} Resolved promise for async function call, or an error if time limit reached
 */
async function asyncCallWithTimeout(asyncPromise, timeLimit) {
    let timeoutHandle;
    const timeoutPromise = new Promise((_resolve, reject) => {
        timeoutHandle = setTimeout(() => reject(new Error('Async call timeout limit reached')), timeLimit);
    });
    return Promise.race([asyncPromise, timeoutPromise]).then((result) => {
        clearTimeout(timeoutHandle);
        return result;
    });
}
exports.asyncCallWithTimeout = asyncCallWithTimeout;
/**
 *
 * @param start Initial day
 * @param end Final day
 * @returns An array of days from start to end
 */
const getDaysArray = (start, end) => {
    start = new Date(start).setHours(0, 0, 0, 0);
    for (var arr = [], dt = new Date(start); dt <= new Date(end); dt.setDate(dt.getDate() + 1)) {
        arr.push(new Date(dt));
    }
    return arr;
};
exports.getDaysArray = getDaysArray;
/**
 *
 * @param Uint8Arr Array of 8-bit unsigned integer
 * @returns An integer
 */
function convertUint8ArrayToInteger(Uint8Arr) {
    var length = Uint8Arr.length;
    let buffer = Buffer.from(Uint8Arr);
    var result = buffer.readUIntBE(0, length);
    return result;
}
exports.convertUint8ArrayToInteger = convertUint8ArrayToInteger;
/**
 * Convert an NFT id into an index
 * @return An index
 */
const nftIdToIndex = (nftId) => {
    if (nftId.indexOf('_') > -1) {
        return 0;
    }
    return convertUint8ArrayToInteger(principal_1.Principal.fromText(nftId).toUint8Array().slice(-4));
};
exports.nftIdToIndex = nftIdToIndex;
// Helper function for tokenIndexToNftId
const to32bits = (num) => {
    let b = new ArrayBuffer(4);
    new DataView(b).setUint32(0, num);
    return Array.from(new Uint8Array(b));
};
/**
 * Convert a token index into a NFT index
 * @param principal Principal ID
 * @param index Index of the current NFT
 * @returns The NFT index in the correct format
 */
const tokenIndexToNftId = (principal, index) => {
    const padding = Buffer.from('\x0Atid');
    const array = new Uint8Array([
        ...padding,
        ...principal_1.Principal.fromText(principal).toUint8Array(),
        ...to32bits(index),
    ]);
    return principal_1.Principal.fromUint8Array(array).toText();
};
exports.tokenIndexToNftId = tokenIndexToNftId;
/**
 * Helper for exponentialMovingAverage
 * @param start Initial number
 * @param length Final number
 * @returns An array of integers
 */
const createIntegerArray = (start, length) => {
    if (length <= 0) {
        return [];
    }
    let index = Math.trunc(start) - 1;
    const end = index + length;
    const array = [];
    while (index++ < end) {
        array.push(index);
    }
    return array;
};
exports.createIntegerArray = createIntegerArray;
/**
 * Display the exponential moving average
 * @param data Array of data to be displayed as ema
 * @param window Integer
 * @returns The exponential moving average of the values
 */
const exponentialMovingAverage = (data, window = 7) => {
    if (!data || data.length < window) {
        return [];
    }
    let index = window - 1;
    const smoothingFactor = 2 / (window + 1);
    const exponentialMovingAverage = (0, exports.createIntegerArray)(0, window - 1).map((i) => i * 0);
    while (index < data.length) {
        const previousEma = exponentialMovingAverage[index - 1];
        const EMA = (data[index] - previousEma) * smoothingFactor + previousEma;
        exponentialMovingAverage.push(EMA);
        ++index;
    }
    return exponentialMovingAverage;
};
exports.exponentialMovingAverage = exponentialMovingAverage;
const isPrincipalId = (id) => {
    const pattern = /^([a-zA-Z0-9]{5}-){10}[a-zA-Z0-9]{3}$/;
    return pattern.test(id);
};
exports.isPrincipalId = isPrincipalId;
