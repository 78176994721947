"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDailyWalletConnections = exports.hasAccess = exports.getCollectionHolderList = exports.getAssetsByAccount = exports.getCollectionCount = exports.getCollection24hPriceData = exports.get24hVolumeTimeseries = exports.getTopVolume = exports.getLatestEventsOfType = exports.getLatestEvents = exports.get7dVolume = exports.get24hVolume = void 0;
const global_1 = require("@/global");
const BASE_PATH = 'statistics/';
const get24hVolume = async (collectionId = 'all') => {
    const api_url = global_1.REST_API + BASE_PATH + '24hVolume/' + collectionId;
    const response = await fetch(api_url);
    return (await response.json());
};
exports.get24hVolume = get24hVolume;
const get7dVolume = async (collectionId = 'all') => {
    const api_url = global_1.REST_API + BASE_PATH + '7dVolume/' + collectionId;
    const response = await fetch(api_url);
    return (await response.json());
};
exports.get7dVolume = get7dVolume;
const getLatestEvents = async (amountToFetch = 10) => {
    const api_url = global_1.REST_API + BASE_PATH + `events?amount=${amountToFetch}`;
    const response = await fetch(api_url);
    return (await response.json());
};
exports.getLatestEvents = getLatestEvents;
const getLatestEventsOfType = async (amountToFetch = 10, types) => {
    const api_url = global_1.REST_API + BASE_PATH + `events?amount=${amountToFetch}&types=${types.join(',')}`;
    const response = await fetch(api_url);
    return (await response.json());
};
exports.getLatestEventsOfType = getLatestEventsOfType;
const getTopVolume = async () => {
    const api_url = global_1.REST_API + BASE_PATH + '24hVolume/timeseries/top';
    const response = await fetch(api_url);
    return (await response.json());
};
exports.getTopVolume = getTopVolume;
const get24hVolumeTimeseries = async (collectionId) => {
    const api_url = global_1.REST_API + BASE_PATH + '24hVolume/timeseries/' + collectionId;
    const response = await fetch(api_url);
    return (await response.json());
};
exports.get24hVolumeTimeseries = get24hVolumeTimeseries;
const getCollection24hPriceData = async (collectionId) => {
    const api_url = global_1.REST_API + BASE_PATH + 'floorPrice/24h/timeseries/' + collectionId;
    const response = await fetch(api_url);
    return (await response.json());
};
exports.getCollection24hPriceData = getCollection24hPriceData;
const getCollectionCount = async (collectionId) => {
    const api_url = global_1.REST_API + BASE_PATH + 'count/' + collectionId;
    const response = await fetch(api_url);
    return (await response.json());
};
exports.getCollectionCount = getCollectionCount;
const getAssetsByAccount = async (id) => {
    const api_url = global_1.REST_API + BASE_PATH + 'assets/' + id;
    const response = await fetch(api_url);
    return (await response.json());
};
exports.getAssetsByAccount = getAssetsByAccount;
const getCollectionHolderList = async (collectionId, page = 1, pageSize = 10, listNfts = false) => {
    const api_url = `${global_1.REST_API}${BASE_PATH}holders/${collectionId}?page=${page}&pageSize=${pageSize}&listNfts=${listNfts}`;
    const response = await fetch(api_url);
    return (await response.json());
};
exports.getCollectionHolderList = getCollectionHolderList;
const hasAccess = async (principal) => {
    const api_url = global_1.REST_API + 'auth/' + 'hasAccess/' + principal;
    const response = await fetch(api_url);
    const result = await response.json();
    return result.access;
};
exports.hasAccess = hasAccess;
const getDailyWalletConnections = async () => {
    const api_url = global_1.REST_API + BASE_PATH + 'wallets/daily';
    const response = await fetch(api_url);
    return (await response.json());
};
exports.getDailyWalletConnections = getDailyWalletConnections;
