"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createCollectionLink = exports.queryCollectionInfo = void 0;
const global_1 = require("@/global");
const yumi_api_1 = require("./yumi-api");
const gold_api_1 = require("./gold-api");
const queryCollectionInfo = async (collectionId) => {
    const idsGld = [];
    const idsExt = [];
    collectionId.forEach((id) => {
        const isGld = global_1.GLD_NFT_CANISTER_IDS.find((item) => item.canisterId === id);
        if (isGld) {
            idsGld.push(isGld.type);
        }
        else {
            idsExt.push(id);
        }
    });
    const dataGld = await (0, gold_api_1.queryCollectionInfo)(idsGld);
    const dataExt = await (0, yumi_api_1.queryCollectionData)(idsExt);
    console.log(dataGld);
    const out = [];
    dataGld.forEach((item) => {
        const is1kg = item.size === '1000g';
        out.push({
            collectionId: item.collectionId,
            logo: `https://prptl.io/-/${item.collectionId}/collection/-/preview-${is1kg ? '1kg' : item.size}.png` ?? global_1.EMPTY_THUMB,
            name: item.data?.name[0] ?? 'not found',
        });
    });
    dataExt.forEach((item) => {
        out.push({
            collectionId: item.info.canisterId,
            logo: item.info.logo[0] ?? global_1.EMPTY_THUMB,
            name: item.info.name,
        });
    });
    return out;
};
exports.queryCollectionInfo = queryCollectionInfo;
const createCollectionLink = (collectionId) => {
    const isGld = global_1.GLD_NFT_CANISTER_IDS.find((item) => item.canisterId === collectionId);
    if (isGld) {
        return `/gold`;
    }
    return `/collection/${collectionId}`;
};
exports.createCollectionLink = createCollectionLink;
