"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getTxHistoryOfAddress = exports.getBalanceHistoryOfAddress = exports.getBalanceOfAddress = void 0;
const OGY_API_URL = 'https://api.origyn.com';
const getBalanceOfAddress = async (address) => {
    const response = await fetch(`${OGY_API_URL}/ogy/balance/${address}`);
    const data = await response.json();
    return data.pop()?.balance || 0;
};
exports.getBalanceOfAddress = getBalanceOfAddress;
const getBalanceHistoryOfAddress = async (address) => {
    const response = await fetch(`${OGY_API_URL}/ogy/balance/history/${address}`);
    const data = await response.json();
    return data;
};
exports.getBalanceHistoryOfAddress = getBalanceHistoryOfAddress;
const getTxHistoryOfAddress = async (address) => {
    const response = await fetch(`${OGY_API_URL}/ogy/transaction/history/${address}`);
    const data = await response.json();
    return data;
};
exports.getTxHistoryOfAddress = getTxHistoryOfAddress;
