export const idlFactory = ({ IDL }) => {
  const EventType = IDL.Variant({
    'auctionDeal' : IDL.Null,
    'offer' : IDL.Null,
    'list' : IDL.Null,
    'claim' : IDL.Null,
    'mint' : IDL.Null,
    'sold' : IDL.Null,
    'acceptOffer' : IDL.Null,
    'point' : IDL.Null,
    'auction' : IDL.Null,
    'transfer' : IDL.Null,
  });
  const TokenIdentifier__1 = IDL.Text;
  const Price = IDL.Nat64;
  const Time = IDL.Int;
  const TokenIdentifier = IDL.Text;
  const RecordEventType = IDL.Variant({
    'auctionDeal' : IDL.Null,
    'offer' : IDL.Null,
    'list' : IDL.Null,
    'claim' : IDL.Null,
    'mint' : IDL.Null,
    'sold' : IDL.Null,
    'acceptOffer' : IDL.Null,
    'point' : IDL.Null,
    'auction' : IDL.Null,
    'transfer' : IDL.Null,
  });
  const Event = IDL.Record({
    'to' : IDL.Opt(IDL.Principal),
    'collection' : IDL.Principal,
    'date' : Time,
    'from' : IDL.Opt(IDL.Principal),
    'item' : TokenIdentifier,
    'index' : IDL.Nat,
    'price' : IDL.Opt(Price),
    'eventType' : RecordEventType,
  });
  const PageParam = IDL.Record({ 'page' : IDL.Nat, 'pageCount' : IDL.Nat });
  const Record = IDL.Service({
    'addEvent' : IDL.Func(
        [
          EventType,
          TokenIdentifier__1,
          IDL.Opt(IDL.Principal),
          IDL.Opt(IDL.Principal),
          IDL.Opt(Price),
        ],
        [],
        [],
      ),
    'addLedgerEvent' : IDL.Func(
        [
          EventType,
          TokenIdentifier__1,
          IDL.Opt(IDL.Principal),
          IDL.Opt(IDL.Principal),
          IDL.Opt(Price),
        ],
        [],
        [],
      ),
    'getCapEventByIndex' : IDL.Func([IDL.Nat], [IDL.Opt(Event)], []),
    'getCollectionEvents' : IDL.Func(
        [IDL.Principal],
        [IDL.Opt(IDL.Vec(Event))],
        [],
      ),
    'getCollectionEventsPageable' : IDL.Func(
        [IDL.Principal, PageParam],
        [IDL.Opt(IDL.Tuple(IDL.Vec(Event), IDL.Nat))],
        [],
      ),
    'getCollectionEventsSize' : IDL.Func(
        [IDL.Principal],
        [IDL.Opt(IDL.Nat)],
        [],
      ),
    'getEventByIndex' : IDL.Func([IDL.Nat], [Event], []),
    'getNftEvents' : IDL.Func(
        [TokenIdentifier__1],
        [IDL.Opt(IDL.Vec(Event))],
        [],
      ),
    'getNftEventsPageable' : IDL.Func(
        [TokenIdentifier__1, PageParam],
        [IDL.Opt(IDL.Tuple(IDL.Vec(Event), IDL.Nat))],
        [],
      ),
    'getUserEvents' : IDL.Func([IDL.Principal], [IDL.Opt(IDL.Vec(Event))], []),
    'init' : IDL.Func([], [], ['oneway']),
    'setUseCap' : IDL.Func([IDL.Bool], [], ['oneway']),
  });
  return Record;
};
export const init = ({ IDL }) => { return [IDL.Principal, IDL.Principal]; };
