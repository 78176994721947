"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.origynNftAgent = exports.extAgent = exports.creditsAgent = exports.recordAgent = exports.yumiAgent = void 0;
const global_1 = require("@/global");
const credits_1 = require("./declarations/credits");
const record_1 = require("./declarations/record");
const yumi_1 = require("./declarations/yumi");
const ext_1 = require("./declarations/ext");
const origyn_nft_1 = require("./declarations/origyn-nft");
exports.yumiAgent = (0, yumi_1.createActor)(global_1.YUMI_CANISTER_ID, { agentOptions: { host: 'https://icp0.io' } });
exports.recordAgent = (0, record_1.createActor)(global_1.RECORD_CANISTER_ID, {
    agentOptions: { host: 'https://icp0.io' },
});
exports.creditsAgent = (0, credits_1.createActor)(global_1.CREDITS_CANISTER_ID, {
    agentOptions: { host: 'https://icp0.io' },
});
const extAgent = (canisterId) => (0, ext_1.createActor)(canisterId, { agentOptions: { host: 'https://icp0.io' } });
exports.extAgent = extAgent;
const origynNftAgent = (canisterId) => (0, origyn_nft_1.createActor)(canisterId, { agentOptions: { host: 'https://icp0.io' } });
exports.origynNftAgent = origynNftAgent;
// export {dashboard as dashboardAgent} from './declarations/dashboard';
