"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.queryTokenIdsInfo = exports.queryCollectionEvents = exports.queryCollectionData = exports.queryTokenInfo = exports.queryCollectionList = void 0;
const ic_agents_1 = require("@/services/ic-agents/");
const misc_1 = require("@/utils/misc");
const principal_1 = require("@dfinity/principal");
// type NftDataReturn = [] | [CollectionData];
const SPECIAL_COLLECTIONS = [
    {
        collectionId: 'n46fk-6qaaa-aaaai-ackxa-cai',
        nftStandard: 'ext-2', //
    },
    {
        collectionId: 'bjcsj-rqaaa-aaaah-qcxqq-cai',
        nftStandard: 'ext-3', // only has getTokenById that returns no thumbnail
    },
];
const queryCollectionList = async () => {
    return await ic_agents_1.yumiAgent.listCollections();
};
exports.queryCollectionList = queryCollectionList;
const queryTokenInfo = async () => {
    // return await yumiAgent();
};
exports.queryTokenInfo = queryTokenInfo;
const queryCollectionData = async (collectionList) => {
    const stats = await ic_agents_1.yumiAgent.getCollectionDatas(collectionList.map((item) => principal_1.Principal.fromText(item)));
    const serialStats = stats.map((item) => {
        // const nftInfo = item[0]?.info;
        // const nftStats = item[0]?.stats[0];
        const nftInfo = item?.info;
        const nftStats = item?.stats[0];
        const myData = {
            info: {
                banner: nftInfo?.banner ?? [],
                canisterId: nftInfo?.canisterId.toString() ?? '',
                category: nftInfo?.category ?? [],
                creator: nftInfo?.creator.toString() ?? '',
                description: nftInfo?.description ?? [],
                featured: nftInfo?.featured ?? [],
                links: nftInfo?.links ?? [],
                logo: nftInfo?.logo ?? [],
                name: nftInfo?.name ?? '',
                releaseTime: Number(nftInfo?.releaseTime),
                royalties: Number(nftInfo?.royalties),
                url: nftInfo?.url ?? [],
            },
            stats: {
                createTime: Number(nftStats?.createTime) / 10 ** 6,
                floorPrice: Number(nftStats?.floorPrice),
                listings: nftStats?.listings.map((listing) => {
                    return {
                        price: Number(listing.price),
                        tokenIdentifier: listing.tokenIdentifier,
                    };
                }) ?? [{ price: NaN, tokenIdentifier: '' }],
                tradeCount: Number(nftStats?.tradeCount),
                volumeTrade: Number(nftStats?.volumeTrade),
            },
        };
        return myData;
    });
    return serialStats;
};
exports.queryCollectionData = queryCollectionData;
const queryCollectionEvents = async (collectionId) => {
    const data = (await ic_agents_1.recordAgent.getCollectionEvents(principal_1.Principal.fromText(collectionId)))[0];
    const txListOut = data.map((item) => {
        const txList = {
            _id: '',
            collectionId: item.collection.toString(),
            date: Number(item.date) / 10 ** 6,
            eventType: Object.keys(item.eventType)[0],
            from: item.from.toString(),
            // index: Number(item.index),
            index: (0, misc_1.convertUint8ArrayToInteger)(principal_1.Principal.fromText(item.item).toUint8Array().slice(-4)),
            item: item.item,
            price: Number(item.price[0]) ?? NaN,
            to: item.to.toString(),
        };
        return txList;
    });
    // return {collectionId, txListOut};
    return txListOut;
};
exports.queryCollectionEvents = queryCollectionEvents;
const queryTokenIdsInfo = async (collectionId, tokenId) => {
    const extAgentInstance = (0, ic_agents_1.extAgent)(collectionId);
    const isSpecial = SPECIAL_COLLECTIONS.find((item) => item.collectionId === collectionId);
    let data;
    // let out: { tokenId: number; metadata: TokenMedadata | undefined }[];
    if (isSpecial) {
        if (isSpecial.nftStandard === 'ext-2') {
            const res = await extAgentInstance.getTokens();
            data = res.filter((item) => tokenId.includes(item[0]));
        }
        else {
            return {
                collectionId,
                metadata: tokenId.map((item) => {
                    return { tokenId: item, metadata: undefined };
                }),
            };
        }
    }
    else {
        data = await extAgentInstance.getTokensByIds(tokenId);
    }
    const metadata = data.map((item) => {
        let metadataJson;
        if ('nonfungible' in item[1]) {
            const metadata = item[1].nonfungible.metadata;
            const tmp = metadata[0] ? metadata[0] : [];
            if (isSpecial) {
                metadataJson = { thumb: new TextDecoder('utf-8').decode(new Uint8Array(tmp)) };
            }
            else {
                metadataJson = JSON.parse(new TextDecoder('utf-8').decode(new Uint8Array(tmp)));
            }
        }
        return { tokenId: item[0], metadata: metadataJson };
    });
    return { collectionId, metadata };
};
exports.queryTokenIdsInfo = queryTokenIdsInfo;
