"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatPrincipal = exports.formatKnownPrinicipals = exports.formatIcpTransaction = exports.formatOgyTransaction = void 0;
const global_1 = require("@/global");
const formatOgyTransaction = (data) => {
    const dataOut = data.map((item) => {
        return {
            Hash: item.txHash,
            Amount: item.amount,
            Fee: item.fee,
            Type: item.type,
            Status: item.status[0],
            Timestamp: item.timestamp,
            From: item.from,
            To: item.to,
        };
    });
    return dataOut;
};
exports.formatOgyTransaction = formatOgyTransaction;
const formatIcpTransaction = (txs) => {
    const dataOut = txs.transactions.map((item) => {
        return {
            Hash: item.hash,
            Amount: +item.details?.amount / 10 ** item.details?.currency.decimals,
            Fee: +item.details?.fee.amount / 10 ** item.details?.fee.currency.decimals,
            Type: item.type,
            Status: item.details?.status,
            Timestamp: item.timestamp,
            From: item.details?.from,
            To: item.details?.to,
        };
    });
    return dataOut;
};
exports.formatIcpTransaction = formatIcpTransaction;
const formatKnownPrinicipals = (value) => {
    return global_1.KNOWN_PRINCIPALS[value] ?? value;
};
exports.formatKnownPrinicipals = formatKnownPrinicipals;
const formatPrincipal = (pid) => {
    return pid.length < 8 ? pid : pid.substring(0, 4) + '...' + pid.substring(pid.length - 4);
};
exports.formatPrincipal = formatPrincipal;
