"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.KNOWN_PRINCIPALS = exports.GLD_NFT_CANISTER_IDS_TESTING = exports.GLD_NFT_CANISTER_IDS = exports.REST_API = exports.EMPTY_THUMB = exports.ICP_DECIMALS = exports.ICP_DASHBOARD_URL = exports.OGY_DASHBOARD_URL = exports.yumiGrantOneAccount = exports.coownedAccount = exports.profitAccount = exports.platformAccount = exports.launchpadAccount = exports.OPIE_CANISTER_ID = exports.OGY_LEDGER_CANISTER_ID = exports.ORIGYN_YUMI_CANISTER_ID = exports.RECORD_CANISTER_ID = exports.CREDITS_CANISTER_ID = exports.YUMI_CANISTER_ID = void 0;
exports.YUMI_CANISTER_ID = 'udtw4-baaaa-aaaah-abc3q-cai';
exports.CREDITS_CANISTER_ID = 'qqamp-7iaaa-aaaah-qakha-cai';
exports.RECORD_CANISTER_ID = 'uesqi-myaaa-aaaah-abc3a-cai';
exports.ORIGYN_YUMI_CANISTER_ID = 'od7d3-vaaaa-aaaap-aamfq-cai';
exports.OGY_LEDGER_CANISTER_ID = 'ryjl3-tyaaa-aaaaa-aaaba-cai';
exports.OPIE_CANISTER_ID = '2oqzn-paaaa-aaaaj-azrla-cai'; // variable -> get from listCollections()
exports.launchpadAccount = '6225c9e1c8df10d441a9ff461bb8688799a75b66eb7ec3009360e76dfb6a9f24';
exports.platformAccount = '88d962bcb90c03675918f4a2609648e092274df657c6a9444308549aa541819d';
exports.profitAccount = '5780292b94594bfc2314f29a81bef85eed2938d89d9b825a4f0f07a8a88609bc';
exports.coownedAccount = 'd0960337ecf0ea7222321e995d6c83259a762a618509dbc397a17bc66faacd99';
exports.yumiGrantOneAccount = '4153e5a9fc575816d25169a300f81ffb3f5601218d3a73e511415c9360c661fe';
exports.OGY_DASHBOARD_URL = 'https://vekja-naaaa-aaaao-aaacq-cai.ic0.app/';
exports.ICP_DASHBOARD_URL = 'https://dashboard.internetcomputer.org/';
exports.ICP_DECIMALS = 8;
exports.EMPTY_THUMB = '/images/empty_thumb.jpeg';
exports.REST_API = '';
switch (process.env.NODE_ENV) {
    case 'production':
        exports.REST_API = 'https://api2.yumi.io/';
        break;
    case 'staging':
        exports.REST_API = 'https://staging.api2.yumi.io/';
        break;
    default:
        exports.REST_API = 'http://localhost/';
        break;
}
exports.GLD_NFT_CANISTER_IDS = [
    {
        canisterId: 'io7gn-vyaaa-aaaak-qcbiq-cai',
        type: '1g',
    },
    {
        canisterId: 'sy3ra-iqaaa-aaaao-aixda-cai',
        type: '10g',
    },
    {
        canisterId: 'zhfjc-liaaa-aaaal-acgja-cai',
        type: '100g',
    },
    {
        canisterId: '7i7jl-6qaaa-aaaam-abjma-cai',
        type: '1000g',
    },
];
exports.GLD_NFT_CANISTER_IDS_TESTING = [
    {
        canisterId: 'obapm-2iaaa-aaaak-qcgca-cai',
        type: '1g',
    },
    {
        canisterId: 'xyo2o-gyaaa-aaaal-qb55a-cai',
        type: '10g',
    },
];
exports.KNOWN_PRINCIPALS = {
    'kvxmz-2wzgu-k5bco-b6xeo-rwxof-jt65n-c5i7j-b6vac-zemky-tutht-7qe': 'BAS',
};
