"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const global_1 = require("@/global");
const react_1 = __importStar(require("react"));
const ImageLoader = ({ placeholder = global_1.EMPTY_THUMB, src, alt, height, style, }) => {
    const [isLoading, setIsLoading] = (0, react_1.useState)(true);
    const [currentSrc, updateCurrentSrc] = (0, react_1.useState)(placeholder);
    // This side effect will run every time this component receives a new src prop.
    (0, react_1.useEffect)(() => {
        // This function will load a new image, and as a side effect, will either update the src
        // to the new image or keep it as the placeholder if loading fails.
        const loadImage = async () => {
            setIsLoading(true);
            let image = new Image();
            try {
                image.src = src;
                await image.decode(); // This will wait until the image is loaded.
                updateCurrentSrc(src); // Update the displayed image.
            }
            catch (error) {
                console.log('Failed to load image', error);
            }
            finally {
                setIsLoading(false);
            }
        };
        loadImage();
    }, [src]); // Only re-run if the src prop changes.
    return (react_1.default.createElement("img", { src: currentSrc, alt: alt, style: isLoading ? { opacity: 0.5 } : style, height: height }));
};
exports.default = ImageLoader;
