"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
require("./MobileNav.less");
const react_1 = __importStar(require("react"));
const react_router_dom_1 = require("react-router-dom");
const MobileNav = (props) => {
    const [mobileNavShown, setMobileNavShown] = (0, react_1.useState)(false);
    const toggleMobileNav = (event) => {
        setMobileNavShown((prev) => !prev);
    };
    const mobileNavStyle = 'mobile-nav' + (mobileNavShown ? ' open' : '');
    return (react_1.default.createElement(react_1.Fragment, null,
        react_1.default.createElement("button", { className: 'toggle-button', onClick: toggleMobileNav },
            react_1.default.createElement("span", { className: 'toggle-button__bar' }),
            react_1.default.createElement("span", { className: 'toggle-button__bar' }),
            react_1.default.createElement("span", { className: 'toggle-button__bar' })),
        react_1.default.createElement("div", { id: 'backdrop', className: mobileNavShown ? 'show' : '', onClick: toggleMobileNav }),
        react_1.default.createElement("nav", { className: mobileNavStyle },
            react_1.default.createElement("div", { className: 'close-button', onClick: toggleMobileNav }, "\u00D7"),
            react_1.default.createElement("ul", { className: 'mobile-nav__items' }, props.sites.map((item) => {
                return (react_1.default.createElement("li", { className: 'mobile-nav__item', key: item.name },
                    react_1.default.createElement(react_router_dom_1.NavLink, { to: item.link, onClick: toggleMobileNav }, item.name)));
            })))));
};
exports.default = MobileNav;
